<template>
  <div>
    <head-layout head-title="任务统计" :head-btn-options="headBtnOptions" @head-cancel="headCancel">
    </head-layout>

    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    />

    <grid-layout
      ref="gridLayout"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :gridRowBtn="gridRowBtn"
      :data-total="page.total"
      :page="page"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      @gird-handle-select-click="handleSelectionChange"
      @grid-romve="toDel"
    ></grid-layout>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>{{ dialogtext }}</span>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="SureToExecute">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {taskStatisticsPage} from "@/api/safe/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

export default {
  components: {
    HeadLayout,
    GridLayout,
  },
  props: {},
  data() {
    return {
      total: '',
      dialogtext: '',
      runState: "",
      btnType: '',
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      taskId: '',
      dialogVisible: false,
      tableLoading: false,
      ExecuteId: "",
      tableData: [],
      gridRowBtn: [
        // {
        //   label: this.$t("cip.cmn.btn.editBtn"),
        //   emit: "grid-view",
        //   remark: "view",
        //   type: "text",
        //   icon: "",
        // },

        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        },
      ],

      headBtnOptions: [
        // {
        //   label: "取消",
        //   emit: "head-cancel",
        //   type: "button",
        //   icon: "",
        //   btnOptType: "cancel",
        // }
      ],

      searchColumns: [
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "培训单位",
          prop: "deptName",
          span: 4,
          maxlength: 10,
        },
      ],

      tableOptions: {
        selection: false,
        customRowAddBtn: false,
        menuWidth: 200,
        menu: false,
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: '培训主题',
            prop: "trainTheme",
            align: "left",
            overHidden: true
          },
          {
            label: '培训单位',
            prop: "deptName",
            align: "left",
            overHidden: true
          },
          {
            label: '通知人',
            prop: "userName",
            align: "right",
            overHidden: true,
            width: 100
          },
          {
            label: '状态',
            prop: "status",
            cell: true,
            type: "select",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_training_use_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            align: 'center',
            dataType: "number",
            width: 90,
          },

        ],
      },
    };
  },

  methods: {
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    gridHeadEmpty(params) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, params)
    },
    gridHeadSearch(params) {
      this.page.currentPage = 1;
      this.onLoad(this.page, params)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      console.log(params, '177')
      taskStatisticsPage(
        page.currentPage,
        page.pageSize,
        this.taskId,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },

    //查看
    toSee(item) {
      this.$router.push({
        path: "/business/safetyenment/train/DeptGroupAdd",
        query: {
          type: "edit",
          id: item.id
        },
      });
    },
    addFn() {
      this.$router.push({
        path: "/business/safetyenment/train/DeptGroupAdd",
        query: {
          type: "add",
        },
      });
    },
    //执行和删除
    SureToExecute() {
      this.dialogVisible = false;
      if (this.btnType == 'del') {
        taskStatisticsPage({id: this.ExecuteId}).then(() => {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.onLoad(this.page)
        })
      }
    },
    toDel(item) {
      this.dialogVisible = true;
      this.dialogtext = '是否删除此任务？'
      this.ExecuteId = item.id;
      this.btnType = 'del'
    }
  },

  mounted() {
    this.taskId = this.$route.query.taskId;
    this.onLoad(this.page);
  },
};
</script>
<style lang="scss" scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}


</style>
